import React from "react"
import FotoRomero from "@components/pageCasosdeExito/image/imgPhotoEmpresa/romero/FotoRomero.png"

//logo de empresas clientes
import Romero from "@components/pageCasosdeExito/image/logoscustomer/Romero.svg"

//iconos de modulos

import prediccion from "@components/pageCasosdeExito/image/iconcards/prediccion.svg"
import llamadas from "@components/pageCasosdeExito/image/iconcards/llamadas.svg"
import integracion from "@components/pageCasosdeExito/image/iconcards/integraciones.svg"
import contabilidad from "@components/pageCasosdeExito/image/iconcards/contabilidad.svg"

const textintro = (
  <span className="">
    <span className="text-underlined-rimac">
      “Romero’D & Asociados, empresa de recupero con operaciones en Ecuador y
      Perú, aprovecha la potencia de
      <span className="strong-intro-casos">
        <span className="underlined-rimac">
          {" "}
          Beex y su marcador predictivo basado en Machine Learning,
        </span>
      </span>{" "}
      para contactar con mas clientes de manera inteligente.”
    </span>
    <br />
    <span className="title-rimac">
      “Romero’D & Asociados, empresa de recupero con operaciones en Ecuador y
      Perú, aprovecha la potencia de
      <span className="strong-intro-casos">
        {" "}
        Beex y su marcador predictivo basado en Machine Learning,{" "}
      </span>{" "}
      para contactar con mas clientes de manera inteligente.”
    </span>
  </span>
)

const text = (
  <span>
    Romero’D & Asociados es una compañía con presencia a nivel Latam,
    específicamente en Ecuador y Perú, dedicada a la prestación de servicios de
    Contact Center, con operaciones de cobranzas en diferentes tramos.
    <br />
    <br />
    Potenciamos la contactabilidad de las campañas de Romero’D & Asociados con
    Beex, nuestro marcador predictivo que trabaja con Machine Learning y
    disgrega con alto índice de éxito los resultados inactivos de los teléfonos.
    <br />
    <br />
    Con Beex, aplicamos los siguientes procesos que potencian su gestión
    diaria:
  </span>
)

const texttwo = (
  <span>
    “Seguimos trabajando con Romero’D para potenciar sus operaciones de cobranzas
    y es un honor que confíen en nosotros durante todos estos años para
    ayudarlos en este fin”
  </span>
)

const data = {
  intro: {
    image: Romero,
    textbody: textintro,
    classlogo: "customerRomero",
  },
  description: {
    textbody: text,
    photo: FotoRomero,
    class:"section-description"
  },
  cards: [
    {
      title: "Predicción",
      img: prediccion,
      info:
        "Marcaciones predictivas basadas en Machine Learning, que mejoran la contactabilidad con sus clientes, llegando a ellos de manera eficiente y en su mejor horario.",
    },
    {
      title: "Llamadas",
      img: llamadas,
      info:
        "Marcaciones progresivas ideales para sus campañas de mora temprana. Disminuyendo sus tiempos muertos gracias al control del ACW.",
    },
    {
      title: "Integraciones",
      img: integracion,
      info:
        "Implementación de procesos automáticos, como reportes, envíos de información, data lakes compartidos, integración mediante APIs y más.",
    },
    {
      title: "Contactabilidad",
      img: contabilidad,
      info:
        "Validamos los resultados de los teléfonos de sus campañas con Beex, potenciando aun mas sus estrategias de marcado automático.",
    },
  ],
  descriptiontwo: {
    textbody: texttwo,
  },
}

export default data
